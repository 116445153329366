import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSack, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flag } from 'components/common/flag'
import { Tooltip } from 'components/ui/tooltip'
import { PriceDTO } from 'models/api/priceDTO'
import { TranslationDTO } from 'models/api/translationDTO'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw, { css } from 'twin.macro'
import { numberWithSpaces } from 'utils/renderHelpers'
/** @jsxImportSource @emotion/react */

const WithIcon = ({
  icon,
  value,
  svgIcon,
  isPrice,
  currency
}: {
  icon?: IconDefinition | any
  value?: string | number | { [key: string]: number }
  svgIcon?: 'play-user' | 'shoping' | 'shoping-green' | 'share'
  isPrice?: boolean
  currency?: 'pln' | 'usd' | 'eur'
}) => {
  return (
    <div tw="grid grid-flow-col justify-start gap-2 items-center">
      <>
        {icon && <FontAwesomeIcon icon={icon as IconProp} tw="text-primary h-4" />}
        {svgIcon && <img width={10} height={10} src={`/images/icons/${svgIcon}.svg`}></img>}
        {typeof value === 'number'
          ? numberWithSpaces(value, isPrice) + (currency ? ` ${currency?.toUpperCase()}` : '')
          : value}
      </>
    </div>
  )
}

const PriceCurrencyList = ({
  icon = faSack,
  list
}: {
  icon?: IconDefinition
  list?: PriceDTO[]
}) => {
  return (
    <div>
      {list?.length
        ? list?.map(({ currency, price }) => (
            <WithIcon
              key={currency}
              icon={icon}
              value={`${numberWithSpaces(price, true)} ${currency?.toUpperCase()}`}
            />
          ))
        : '-'}
    </div>
  )
}

export type iconRoundedColor =
  | 'gray'
  | '#46CF73'
  | '#FF0000'
  | '#F05161'
  | '#2374E1'
  | 'black'
  | 'error'
  | 'white'
export interface IconRoundedProps {
  icon?: IconDefinition
  svgIcon?: string
  value?: string | number
  color?: iconRoundedColor
  whiteBackground?: boolean
  small?: boolean
  isScale?: boolean
}

const IconRounded = ({
  icon,
  value,
  color = 'white',
  whiteBackground = false,
  small = false,
  isScale,
  svgIcon
}: IconRoundedProps) => {
  return (
    <div
      css={[
        tw`cursor-pointer h-10 w-10 rounded-full flex justify-center items-center bg-gray-700`,
        whiteBackground && tw`bg-white`,
        small && tw`h-7 w-7`,
        isScale && tw`hover:scale-110 transition-all duration-200`
      ]}
    >
      {icon && <FontAwesomeIcon css={[tw`h-4`, small && tw`h-3`]} icon={icon} color={color} />}
      {svgIcon && <img css={[tw`h-4`, small && tw`h-5`]} src="images/logo-dark.svg"></img>}
      {value}
    </div>
  )
}

const TruncateText = ({
  text,
  lineNumber = 2,
  showButton,
  id,
  lang
}: {
  text?: string
  lineNumber?: number
  className?: string
  showButton?: boolean
  id?: number
  lang?: string
}) => {
  const { t } = useTranslation('common')
  const [hidden, setHidden] = useState(true)
  const [isOverflow, setIsOverflow] = useState(false)

  const className = lang ? `truncateText-${id}-${lang}` : `truncateText-${id}`

  useEffect(() => {
    const element = document.querySelector(`.${className}`)
    const clientHeight = element?.clientHeight || 72
    const scrollHeight = element?.scrollHeight || 72
    if (scrollHeight > clientHeight) {
      setIsOverflow(true)
    }
  }, [className])

  return (
    <div key={text} onClick={() => showButton && setHidden(!hidden)}>
      <div
        className={showButton ? className : ''}
        css={[
          tw`m-0 mr-auto`,
          css`
            overflow-wrap: anywhere;
          `,
          hidden &&
            css`
              display: -webkit-box;
              -webkit-line-clamp: ${lineNumber};
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            `
        ]}
      >
        {text}
      </div>
      {isOverflow && showButton && (
        <p tw="text-gray-200 text-xs cursor-pointer">
          {hidden ? t('buttons.showMore') : t('buttons.showLess')}
        </p>
      )}
    </div>
  )
}

interface TextWithFlagProps {
  list?: TranslationDTO[]
  isTruncate?: boolean
  isMobile?: boolean
  id?: number
  className?: string
}

const TextWithFlag = ({ list, isMobile, isTruncate, id, className }: TextWithFlagProps) => {
  return (
    <div tw="grid gap-2">
      {list?.map(({ language, text }) => (
        <div key={`${text || ''}${language || ''}`} tw="flex space-x-2">
          {language && <Flag language={language} />}
          {isMobile ? (
            <TruncateText text={text} showButton={isTruncate} id={id} lang={language} />
          ) : (
            <>
              {isTruncate ? (
                <TruncateTextTooltip text={text} lineNumber={1} />
              ) : (
                <span className={className}>{text}</span>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

const TruncateTextTooltip = ({
  text,
  lineNumber = 2,
  className,
  content
}: {
  text?: string
  lineNumber?: number
  className?: string
  content?: JSX.Element
}) => {
  let descriptionParsed
  try {
    descriptionParsed = JSON.parse(text ?? '')
  } catch (e) {
    descriptionParsed = text
  }
  return (
    <div>
      <Tooltip
        trigger={
          <div
            css={[
              tw`m-0 mr-auto text-2xs`,
              css`
                display: -webkit-box;
                -webkit-line-clamp: ${lineNumber};
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: anywhere;
              `
            ]}
            className={className}
          >
            <div dangerouslySetInnerHTML={{ __html: descriptionParsed }} />
          </div>
        }
      >
        <div style={{ wordBreak: 'break-all' }} tw="max-w-lg">
          {content ?? text}
        </div>
      </Tooltip>
    </div>
  )
}

export { IconRounded, PriceCurrencyList, TextWithFlag, TruncateText, TruncateTextTooltip, WithIcon }
