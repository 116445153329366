import { Button } from 'components/ui/button'
import { useAuth } from 'context/authProvider'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

/** @jsxImportSource @emotion/react */

export default function StepTwo() {
  const { user } = useAuth()
  const { isAppLogin } = user || {}
  const { t: tCreator } = useTranslation('creator')
  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation('product')

  return (
    // <div tw="min-h-[376px]">
    <div tw="xl:min-h-[298px] min-h-[376px] pointer-events-none">
      <div tw="flex px-3 xl:pl-[24px] xl:mt-6 xl:ml-6 items-center h-16 justify-between flex-shrink-0 sm:pr-13 pr-11 xl:pr-3">
        <p tw="text-xl text-primary font-bold">{t('itemHeader.promotionalVideos')}</p>
        {!isAppLogin && (
          <Button tw="h-10 pointer-events-none xl:mr-[76px]" color={'primary'} type="button">
            {tCommon('buttons.add')}
          </Button>
        )}
      </div>

      <div tw="min-h-[104px] xl:min-h-[40px]">
        <p tw="text-3xs xl:text-2xs xl:pl-[24px] xl:ml-6 xl:max-w-4xl md:max-w-md max-w-[220px] pl-3">
          {tCreator('onBoarding.video.description')}
        </p>
      </div>

      <div tw="relative xl:w-full">
        <p tw="text-xs font-semibold px-4 mt-8 opacity-0">
          {tCreator('onBoarding.video.addVideo')}
        </p>
        <div
          tw="xl:hidden absolute w-[127px] h-[137px] right-12 bottom-4"
          css={[isAppLogin ? tw`bottom-16 -right-1` : tw`right-12 bottom-4`]}
        >
          <img src="/images/onBoarding/arrow-step-three.svg" alt="bonuses" />
        </div>
        <div tw="absolute w-[127px] h-[90px] xl:right-56 xl:-bottom-28 right-60 -bottom-8">
          <p tw="text-xs font-semibold px-4 mt-8 whitespace-nowrap">
            {tCreator('onBoarding.video.addVideo')}
          </p>
        </div>
        <div tw="xl:block hidden absolute w-[127px] h-[90px] right-14 bottom-1">
          <img src="/images/onBoarding/desktop/arrow-step-two.svg" alt="bonuses" />
        </div>
      </div>
    </div>
  )
}
