import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactNode } from 'react'
import { Oval } from 'react-loader-spinner'
import styled, { css } from 'styled-components'
import tw, { theme } from 'twin.macro'
/** @jsxImportSource @emotion/react */

export type Variants = 'primary' | 'secondary' | 'gray' | 'lightGray' | 'red'

interface ButtonBase {
  color?: Variants
  isDisabled?: boolean
  isLoading?: boolean
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBase & {
    icon?: IconDefinition
    iconRotation?: number
    children?: ReactNode
  }

const Button = ({ isLoading, icon, children, iconRotation = 0, ...props }: ButtonProps) => (
  <ButtonStyled hasIcon={!!icon} hasChildren={!!children} isLoading={isLoading} {...props}>
    {icon && (
      <div tw="h-9 w-9 flex items-center justify-center">
        <FontAwesomeIcon
          transform={{ rotate: iconRotation }}
          icon={icon}
          tw="h-4"
          aria-label="icon"
        />
      </div>
    )}
    {children}
    {isLoading && (
      <span tw="h-5 w-5 absolute flex items-center justify-center">
        <Oval tw="h-4" height="26px" color={theme`colors.gray.100`} />
      </span>
    )}
  </ButtonStyled>
)

type ButtonStyledProps = ButtonBase & {
  hasIcon: boolean
  hasChildren: boolean
}

const colors = {
  primary: tw`bg-transparent text-primary border-primary hocus:(text-gray-900 bg-primary)`,
  secondary: tw`bg-transparent border-gray-100 text-gray-100 hocus:(text-gray-900 bg-gray-100)`,
  gray: tw`bg-transparent  border-gray-200 text-gray-200 hocus:(text-gray-900 bg-gray-200)`,
  lightGray: tw`text-black bg-gray-900 border-gray-900 hocus:(text-gray-900 bg-gray-900) border`,
  red: tw`text-error bg-error border-error hocus:(text-gray-100 bg-error)`
}

const colorsIsLoading = {
  primary: tw`bg-primary hocus:text-primary`,
  secondary: tw`bg-gray-900 hocus:text-gray-900`,
  gray: tw`bg-gray-600 hocus:text-gray-800`,
  lightGray: tw`bg-gray-900 text-gray-900 hocus:text-gray-800`,
  red: tw`bg-error hocus:text-error`
}

const ButtonStyled = styled.button<ButtonStyledProps>(
  ({ color = 'primary', isDisabled = false, isLoading, hasIcon, hasChildren }) => [
    css`
      width: fit-content;
      max-width: -webkit-fill-available;
    `,
    tw`relative cursor-pointer flex px-4 xl:px-8 py-2 border-2 border-solid rounded-md font-bold focus:outline-none transform duration-100  justify-center items-center flex-shrink-0 `,
    hasIcon && tw`pl-0`,
    !hasChildren && tw`p-0`,
    colors[color],
    isLoading && [tw`pointer-events-none`, colorsIsLoading[color]],
    isDisabled &&
      color !== 'secondary' &&
      tw`text-primary-transparent border-primary-transparent hocus:(text-primary-transparent bg-white) pointer-events-none`,
    isDisabled && color === 'secondary' && tw`opacity-50`
  ]
)

export { Button }
