import { Oval } from 'react-loader-spinner'
import tw from 'twin.macro'
import { ColorVariant, getColor } from 'utils/renderHelpers'
/** @jsxImportSource @emotion/react */

const Spinner = ({
  color = 'white',
  isAbsolute = true
}: {
  color?: ColorVariant
  isAbsolute?: boolean
}) => {
  return (
    <div
      css={[
        isAbsolute
          ? tw`absolute top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2`
          : tw`my-20 flex place-content-center`
      ]}
    >
      <Oval color={getColor(color)} secondaryColor="#AAAAAA" />
    </div>
  )
}

export { Spinner }
