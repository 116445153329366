import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { CloudMenu } from './cloudMenu'
/** @jsxImportSource @emotion/react */

interface SideBarItemProps {
  icon: IconDefinition
  path: string
  index: number
  setIsTuch: React.Dispatch<React.SetStateAction<string>>
  istouch: string
  pathname: string
}

export const SideBarItem = ({
  icon,
  path,
  index,
  setIsTuch,
  istouch,
  pathname
}: SideBarItemProps) => {
  const { t } = useTranslation('common')

  return (
    <WrapperSideBarItem
      onTouchEnd={() =>
        setTimeout(() => {
          setIsTuch('true')
        }, 3000)
      }
      istouch={istouch}
      key={`${path}+${index}`}
      to={path}
    >
      <FontAwesomeIcon
        icon={icon as IconProp}
        size="xs"
        css={[pathname.includes(path) && tw`text-primary`]}
      />
      <CloudMenu pageName={t(`navigation.${path as string}` as never)} />
    </WrapperSideBarItem>
  )
}

export const WrapperSideBarItem = styled(NavLink)(({ istouch }: { istouch: string }) => [
  css`
    ${tw`h-14 flex items-center justify-center relative text-gray-100 cursor-pointer`}
    @media screen and (max-height: 700px) {
      height: 45px;
    }
    svg {
      ${tw`h-5`}
    }
    :hover > div {
      ${istouch === 'true' ? tw`hidden` : tw`flex`}
    }
  `
])
