/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { PropsWithChildren, ReactNode } from 'react'
import tw from 'twin.macro'
import { DefaultCloseButton } from './defaultCloseButton'
import { DialogCloseButton } from './dialogCloseButton'
import { useDialogContext } from './dialogProvider'
/** @jsxImportSource @emotion/react */

export type DialogSizes = '7xl' | '6xl' | '5xl' | '4xl' | '3xl' | 'md' | 'sm'

type DialogContentsProps = {
  title?: string | ReactNode
  isCloseBtn?: boolean
  children: ReactNode
  size?: DialogSizes
  onDismissDefault?: boolean
  onClickCloseButton?: () => void
}

const sizes = {
  '7xl': tw`md:max-w-7xl`,
  '6xl': tw`md:max-w-6xl`,
  '5xl': tw`md:max-w-5xl`,
  '4xl': tw`md:max-w-4xl`,
  '3xl': tw`md:max-w-3xl`,
  md: tw`md:max-w-md`,
  sm: tw`md:max-w-sm`
}

const DialogContents = ({
  title,
  isCloseBtn = true,
  children,
  size = '3xl',
  onDismissDefault = false,
  onClickCloseButton,
  ...props
}: PropsWithChildren<DialogContentsProps>) => {
  const [isOpen, setIsOpen] = useDialogContext()

  return (
    <DialogOverlay
      allowPinchZoom
      isOpen={isOpen}
      onDismiss={() => (onDismissDefault ? setIsOpen(false) : undefined)}
      tw="z-50 bg-black-transparent"
    >
      <DialogContent
        tw="m-0 w-full rounded-md bg-gray-800 text-gray-100 min-h-screen md:min-h-0 h-auto relative p-0 md:(mx-auto my-dialog-spacing)"
        css={[sizes[size]]}
        aria-label="app modal"
      >
        {isCloseBtn && (
          <div tw="flex justify-end">
            <DialogCloseButton>
              <DefaultCloseButton onClick={onClickCloseButton} />
            </DialogCloseButton>
          </div>
        )}
        <div tw="grid">
          {title && (
            <div tw="flex items-center py-5 px-7 pr-14 border-b border-gray-600">
              <p tw="md:text-xl text-primary font-bold">{title}</p>
            </div>
          )}
          <div {...props}>{children}</div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}

export { DialogCloseButton, DialogContents }
